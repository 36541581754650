// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WhoWeAre_who__U1rDJ {
  margin-bottom: 75px;
}

.WhoWeAre_titles__1ldDb h1 {
  font-family: "Gerbil";
  font-size: 4rem;
  font-weight: 400;
  color: #1b3942;
  margin-top: 6.25rem;
  margin-bottom: 40px;
  line-height: 150%;
}

.WhoWeAre_titles__1ldDb p {
  font-size: 1.25rem;
  font-weight: 300;
  color: #1b3942;
}

.WhoWeAre_values__hY\\+4l {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-block: 6.25rem;
}

.WhoWeAre_value__7oUeX {
  color: #94792f;
  text-align: center;
}

.WhoWeAre_value__7oUeX h6 {
  font-family: "Gerbil";
  font-size: 3rem;
  font-weight: 400;
}

.WhoWeAre_value__7oUeX p {
  font-size: 20px;
  font-weight: 300;
  color: #94792f;
}

@media screen and (max-width: 767px) {
  .WhoWeAre_titles__1ldDb {
    text-align: center;
  }

  .WhoWeAre_values__hY\\+4l {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 40px;
  }
}

@media screen and (max-width: 425px) {
  .WhoWeAre_values__hY\\+4l {
    grid-template-columns: 1fr;
    row-gap: 40px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/WhoWeAre/WhoWeAre.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,mBAAmB;EACnB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,qCAAqC;EACrC,SAAS;EACT,qBAAqB;AACvB;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE;IACE,kBAAkB;EACpB;;EAEA;IACE,qCAAqC;IACrC,aAAa;EACf;AACF;;AAEA;EACE;IACE,0BAA0B;IAC1B,aAAa;EACf;AACF","sourcesContent":[".who {\n  margin-bottom: 75px;\n}\n\n.titles h1 {\n  font-family: \"Gerbil\";\n  font-size: 4rem;\n  font-weight: 400;\n  color: #1b3942;\n  margin-top: 6.25rem;\n  margin-bottom: 40px;\n  line-height: 150%;\n}\n\n.titles p {\n  font-size: 1.25rem;\n  font-weight: 300;\n  color: #1b3942;\n}\n\n.values {\n  display: grid;\n  grid-template-columns: repeat(4, 1fr);\n  gap: 20px;\n  margin-block: 6.25rem;\n}\n\n.value {\n  color: #94792f;\n  text-align: center;\n}\n\n.value h6 {\n  font-family: \"Gerbil\";\n  font-size: 3rem;\n  font-weight: 400;\n}\n\n.value p {\n  font-size: 20px;\n  font-weight: 300;\n  color: #94792f;\n}\n\n@media screen and (max-width: 767px) {\n  .titles {\n    text-align: center;\n  }\n\n  .values {\n    grid-template-columns: repeat(2, 1fr);\n    row-gap: 40px;\n  }\n}\n\n@media screen and (max-width: 425px) {\n  .values {\n    grid-template-columns: 1fr;\n    row-gap: 40px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"who": `WhoWeAre_who__U1rDJ`,
	"titles": `WhoWeAre_titles__1ldDb`,
	"values": `WhoWeAre_values__hY+4l`,
	"value": `WhoWeAre_value__7oUeX`
};
export default ___CSS_LOADER_EXPORT___;
