import React from "react";

// Styles
import Style from "./WhyUs.module.css";
import Wrapper from "../Wrapper/Wrapper";
import why1 from "../../assets/images/why1.jpg";
import why2 from "../../assets/images/why23.jpg";
import why3 from "../../assets/images/MARKET.png";
import why4 from "../../assets/images/why4.jpg";

const data = [
  {
    img: why1,
    title: "Expert Guidance",
    subTitle:
      "Our team of seasoned professionals offers deep insights into the real estate market.",
  },
  {
    img: why2,
    title: "Client-Centered Approach",
    subTitle:
      "We prioritize your goals, whether you're buying, selling, or investing.",
  },
  {
    img: why3,
    title: "Market Knowledge",
    subTitle:
      "With extensive knowledge of the latest trends and developments, we provide unmatched expertise in property transactions.",
  },
  {
    img: why4,
    subTitle:
      " Our business is built on trust, and we are committed to transparency in all our dealings.",
    title: "Trust & Integrity:",
  },
];

const WhyUs = () => {
  return (
    <Wrapper>
      <div className={Style.why}>
        <h1>Why Choose Us</h1>
        <h4>At Solitaire Real Estate, we take pride in:</h4>
        <div className={Style.whyGrid}>
          {data.map((item, index) => {
            return <Why key={index} id={index} {...item} />;
          })}
        </div>
      </div>
    </Wrapper>
  );
};

const Why = ({ id, title, img, subTitle }) => {
  return (
    <div
      style={{
        backgroundImage: `url(${img})`,
      }}
      className={Style.whyOne}
    >
      <div>
        <h5>{title}</h5>
        <p>{subTitle}</p>
      </div>
      <h2>0{id + 1}</h2>
    </div>
  );
};

export default WhyUs;
