// Imports
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePageView from "./pages/main/HomePageView/HomePageView";

// Styles
import "./assets/css/index.css";
import PrivacyPolicyView from "./pages/main/PrivacyPolicyView/PrivacyPolicyView";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePageView />} />
        {/* <Route path="/privacy-policy" element={<PrivacyPolicyView />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
