// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PrivacyPolicyView_privacyWrapper__eJ87R h1 {
  font-family: "Gerbil";
  font-size: 4rem;
  font-weight: 400;
  color: #1b3942;
  margin-block: 2.25rem;
  line-height: 150%;
}

.PrivacyPolicyView_container__PaUqv {
  max-width: 800px;
  margin: auto;
  padding: 2rem;
  color: #333;
  line-height: 1.6;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.PrivacyPolicyView_title__aIEoe {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  text-align: center;
  color: #003366;
}

.PrivacyPolicyView_intro__7v4fY {
  margin-bottom: 2rem;
  font-size: 1.1rem;
  color: #555;
}

.PrivacyPolicyView_section__TqB6M {
  margin-bottom: 1.5rem;
}

.PrivacyPolicyView_section__TqB6M h2 {
  font-size: 1.75rem;
  color: #003366;
  margin-bottom: 1rem;
}

.PrivacyPolicyView_section__TqB6M p {
  font-size: 1rem;
  color: #333;
}

.PrivacyPolicyView_list__Aimqw {
  padding-left: 1.5rem;
  margin-top: 1rem;
}

.PrivacyPolicyView_list__Aimqw li {
  margin-bottom: 0.5rem;
  line-height: 1.5;
}

.PrivacyPolicyView_link__g4HDX {
  color: #0066cc;
  text-decoration: none;
}

.PrivacyPolicyView_link__g4HDX:hover {
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/pages/main/PrivacyPolicyView/PrivacyPolicyView.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb,WAAW;EACX,gBAAgB;EAChB,8BAA8B;EAC9B,yBAAyB;EACzB,kBAAkB;EAClB,yCAAyC;AAC3C;;AAEA;EACE,iBAAiB;EACjB,qBAAqB;EACrB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,oBAAoB;EACpB,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".privacyWrapper h1 {\n  font-family: \"Gerbil\";\n  font-size: 4rem;\n  font-weight: 400;\n  color: #1b3942;\n  margin-block: 2.25rem;\n  line-height: 150%;\n}\n\n.container {\n  max-width: 800px;\n  margin: auto;\n  padding: 2rem;\n  color: #333;\n  line-height: 1.6;\n  font-family: Arial, sans-serif;\n  background-color: #f9f9f9;\n  border-radius: 8px;\n  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);\n}\n\n.title {\n  font-size: 2.5rem;\n  margin-bottom: 1.5rem;\n  text-align: center;\n  color: #003366;\n}\n\n.intro {\n  margin-bottom: 2rem;\n  font-size: 1.1rem;\n  color: #555;\n}\n\n.section {\n  margin-bottom: 1.5rem;\n}\n\n.section h2 {\n  font-size: 1.75rem;\n  color: #003366;\n  margin-bottom: 1rem;\n}\n\n.section p {\n  font-size: 1rem;\n  color: #333;\n}\n\n.list {\n  padding-left: 1.5rem;\n  margin-top: 1rem;\n}\n\n.list li {\n  margin-bottom: 0.5rem;\n  line-height: 1.5;\n}\n\n.link {\n  color: #0066cc;\n  text-decoration: none;\n}\n\n.link:hover {\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"privacyWrapper": `PrivacyPolicyView_privacyWrapper__eJ87R`,
	"container": `PrivacyPolicyView_container__PaUqv`,
	"title": `PrivacyPolicyView_title__aIEoe`,
	"intro": `PrivacyPolicyView_intro__7v4fY`,
	"section": `PrivacyPolicyView_section__TqB6M`,
	"list": `PrivacyPolicyView_list__Aimqw`,
	"link": `PrivacyPolicyView_link__g4HDX`
};
export default ___CSS_LOADER_EXPORT___;
