// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WhyUs_why__FJIzI {
  margin-block: 6rem;
}

.WhyUs_why__FJIzI h1 {
  font-family: "Gerbil";
  font-size: 4rem;
  font-weight: 400;
}

.WhyUs_why__FJIzI h4 {
  margin-block: 1.5rem 3rem;
  font-weight: 300;
  color: #1b3942;
}

.WhyUs_whyGrid__kidJu {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 4rem;
}

.WhyUs_whyOne__Ixpc5 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* background-size: 100% 100%; */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 450px;
  padding: 20px;
  width: 100%;
  border: 1px solid white;
  color: white;
  transition: all 0.3s ease;
}

.WhyUs_whyOne__Ixpc5:hover {
  color: #94792f;
  border: 1px solid #94792f;
  background-color: white;
  background-size: 0%;
}

.WhyUs_whyOne__Ixpc5 h2 {
  font-family: "Gerbil";
  font-size: 40px;
  font-weight: 400;
}

.WhyUs_whyOne__Ixpc5 h5 {
  margin-bottom: 20px;
}

@media screen and (max-width: 991px) {
  .WhyUs_whyGrid__kidJu {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 576px) {
  .WhyUs_why__FJIzI h1,
  .WhyUs_why__FJIzI h4 {
    text-align: center;
  }

  .WhyUs_whyOne__Ixpc5 {
    height: 350px;
  }

  .WhyUs_whyGrid__kidJu {
    grid-template-columns: repeat(1, 1fr);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/WhyUs/WhyUs.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,qCAAqC;EACrC,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,gCAAgC;EAChC,sBAAsB;EACtB,2BAA2B;EAC3B,4BAA4B;EAC5B,aAAa;EACb,aAAa;EACb,WAAW;EACX,uBAAuB;EACvB,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,yBAAyB;EACzB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE;IACE,qCAAqC;EACvC;AACF;;AAEA;EACE;;IAEE,kBAAkB;EACpB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,qCAAqC;EACvC;AACF","sourcesContent":[".why {\n  margin-block: 6rem;\n}\n\n.why h1 {\n  font-family: \"Gerbil\";\n  font-size: 4rem;\n  font-weight: 400;\n}\n\n.why h4 {\n  margin-block: 1.5rem 3rem;\n  font-weight: 300;\n  color: #1b3942;\n}\n\n.whyGrid {\n  display: grid;\n  grid-template-columns: repeat(4, 1fr);\n  gap: 4rem;\n}\n\n.whyOne {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  /* background-size: 100% 100%; */\n  background-size: cover;\n  background-position: center;\n  background-repeat: no-repeat;\n  height: 450px;\n  padding: 20px;\n  width: 100%;\n  border: 1px solid white;\n  color: white;\n  transition: all 0.3s ease;\n}\n\n.whyOne:hover {\n  color: #94792f;\n  border: 1px solid #94792f;\n  background-color: white;\n  background-size: 0%;\n}\n\n.whyOne h2 {\n  font-family: \"Gerbil\";\n  font-size: 40px;\n  font-weight: 400;\n}\n\n.whyOne h5 {\n  margin-bottom: 20px;\n}\n\n@media screen and (max-width: 991px) {\n  .whyGrid {\n    grid-template-columns: repeat(2, 1fr);\n  }\n}\n\n@media screen and (max-width: 576px) {\n  .why h1,\n  .why h4 {\n    text-align: center;\n  }\n\n  .whyOne {\n    height: 350px;\n  }\n\n  .whyGrid {\n    grid-template-columns: repeat(1, 1fr);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"why": `WhyUs_why__FJIzI`,
	"whyGrid": `WhyUs_whyGrid__kidJu`,
	"whyOne": `WhyUs_whyOne__Ixpc5`
};
export default ___CSS_LOADER_EXPORT___;
