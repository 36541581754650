// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PartnersSlide_partners__Wf\\+hR {
  margin-block: 50px 6rem;
  max-width: 1990px;
  margin-inline: auto;
  color: #1b3942;
}

.PartnersSlide_partners__Wf\\+hR h1 {
  font-family: "Gerbil";
  font-size: 3.25rem;
  font-weight: 400;
  color: #1b3942;
  line-height: 150%;
  margin-top: 40px;
}

.PartnersSlide_partners__Wf\\+hR p {
  font-size: 1.25rem;
  margin-block: 15px 30px;
}

.PartnersSlide_card__waeWA {
  height: 50px;
  width: 150px;
  cursor: pointer;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
`, "",{"version":3,"sources":["webpack://./src/components/PartnersSlide/PartnersSlide.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,iBAAiB;EACjB,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;EAChB,cAAc;EACd,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,2BAA2B;EAC3B,wBAAwB;EACxB,4BAA4B;AAC9B","sourcesContent":[".partners {\r\n  margin-block: 50px 6rem;\r\n  max-width: 1990px;\r\n  margin-inline: auto;\r\n  color: #1b3942;\r\n}\r\n\r\n.partners h1 {\r\n  font-family: \"Gerbil\";\r\n  font-size: 3.25rem;\r\n  font-weight: 400;\r\n  color: #1b3942;\r\n  line-height: 150%;\r\n  margin-top: 40px;\r\n}\r\n\r\n.partners p {\r\n  font-size: 1.25rem;\r\n  margin-block: 15px 30px;\r\n}\r\n\r\n.card {\r\n  height: 50px;\r\n  width: 150px;\r\n  cursor: pointer;\r\n  background-position: center;\r\n  background-size: contain;\r\n  background-repeat: no-repeat;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"partners": `PartnersSlide_partners__Wf+hR`,
	"card": `PartnersSlide_card__waeWA`
};
export default ___CSS_LOADER_EXPORT___;
