import React, { useRef } from "react";

// Librairies
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules"; // Ensure Autoplay is imported

// Images
import emaar from "../../assets/images/emaar.jpg";
import dh from "../../assets/images/dubaiHolding.png";
import damac from "../../assets/images/damac.jpg";
import sobha from "../../assets/images/sobha.jpg";
import danube from "../../assets/images/Danube.png";
import eling from "../../assets/images/Ellington.png";
import omn from "../../assets/images/omniyat.jpg";
import bin from "../../assets/images/Binghatti.png";
import alda from "../../assets/images/ALDAR.png";

// Styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import Style from "./PartnersSlide.module.css";

const partners = [
  {
    logo: emaar,
    href: "https://www.emaar.com/",
  },
  {
    logo: dh,
    href: "https://dubaiholding.com/en/",
  },
  {
    logo: damac,
    href: "https://www.damacproperties.com/en/",
  },
  {
    logo: sobha,
    href: "https://www.sobharealty.com/",
  },
  {
    logo: danube,
    href: "https://danubeproperties.com/",
  },
  {
    logo: eling,
    href: "https://ellingtonproperties.ae/en",
  },
  {
    logo: omn,
    href: "https://www.omniyat.com/",
  },
  {
    logo: bin,
    href: "https://www.binghatti.com/",
  },
  {
    logo: alda,
    href: "https://www.aldar.com/en",
  },
];

const PartnersSlide = () => {
  const swiperRef = useRef();

  return (
    <div className={Style.partners}>
      <h1>Partners</h1>
      <p>
        We are partnered with the Leading Developers in the UAE to give you
        exclusive access to premium properties and investment opportunities. Our
        deep relationships with top developers ensure you receive the best
        options in the market, tailored to your needs.
      </p>
      <Swiper
        modules={[Pagination, Autoplay]}
        slidesPerView={4}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        spaceBetween={25}
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          380: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 4,
          },
          1050: {
            slidesPerView: 5,
          },
          1440: {
            slidesPerView: 6,
          },
        }}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
      >
        {partners.map((item, index) => {
          return (
            <SwiperSlide {...item} key={index}>
              {() => <SingleCardActive logo={item.logo} href={item.href} />}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

const SingleCardActive = ({ logo, href }) => {
  return (
    <a href={href} target="_blank" rel="noreferrer">
      <div
        style={{
          backgroundImage: `url(${logo})`,
        }}
        className={Style.card}
      />
    </a>
  );
};

export default PartnersSlide;
