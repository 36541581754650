import React, { useState } from "react";
import Wrapper from "../Wrapper/Wrapper";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Button from "../Button/Button";
import axios from "axios";
import Style from "./ContactForm.module.css";

const ContactForm = () => {
  const [apiStatus, setApiStatus] = useState({ error: false, message: "" });

  // Schema
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    phone: Yup.string()
      .matches(/^[0-9]+$/, "Phone number must be digits only")
      .min(10, "Phone number must be at least 10 digits")
      .required("Phone number is required"),
    message: Yup.string().required("Message is required"),
  });

  return (
    <Wrapper>
      <div className={Style.headings}>
        <div>
          <h1>Contact Us</h1>
          <p>
            Whether you're ready to start your real estate journey or have
            questions, our team is here to assist you.Reach out to us today for
            a consultation, and let Solitaire Real Estate guide you to your next
            property success.
          </p>
        </div>
        <div />
      </div>
      <div className={Style.formGrid}>
        <div className={Style.map}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.4597898366637!2d55.261617900000005!3d25.1877123!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69d72d8193ab%3A0xcf59cc34ff9b036f!2sOne%20by%20Omniyat%20-%20Business%20Bay%20-%20Dubai!5e0!3m2!1sen!2sae!4v1729773655214!5m2!1sen!2sae"
            width="100%"
            height="100%"
            allowfullscreen=""
            loading="lazy"
            title="Solitaire Crest Location"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div className={Style.form}>
          <Formik
            initialValues={{ name: "", email: "", phone: "", message: "" }}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              try {
                // Make an API call with Axios
                const response = await axios.post(
                  "https://solitaire-crest-backend.vercel.app/contact",
                  values
                );

                if (response.status === 201) {
                  setApiStatus({
                    error: false,
                    message: "Form submitted successfully",
                  });
                  setTimeout(() => {
                    setApiStatus({
                      error: false,
                      message: "",
                    });
                  }, 5000);
                  // resetForm();
                }
              } catch (error) {
                setApiStatus({
                  error: true,
                  message: "Something went wrong please try again later",
                });
                setTimeout(() => {
                  setApiStatus({
                    error: false,
                    message: "",
                  });
                }, 5000);
                // resetForm();
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting, errors, touched, values }) => (
              <Form>
                <div className={Style.form}>
                  <div>
                    <Field
                      type="text"
                      name="name"
                      className={
                        touched.name && errors.name ? Style.error : Style.field
                      }
                      placeholder="Name"
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      style={{ color: "red", fontSize: "13px" }}
                    />
                  </div>

                  <div>
                    <Field
                      type="email"
                      name="email"
                      className={
                        touched.email && errors.email
                          ? Style.error
                          : Style.field
                      }
                      placeholder="Email Address"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      style={{ color: "red", fontSize: "13px" }}
                    />
                  </div>

                  <div>
                    <Field
                      type="text"
                      name="phone"
                      className={
                        touched.phone && errors.phone
                          ? Style.error
                          : Style.field
                      }
                      placeholder="Phone Number"
                    />
                    <ErrorMessage
                      name="phone"
                      component="div"
                      style={{ color: "red", fontSize: "13px" }}
                    />
                  </div>

                  <div>
                    <Field
                      as="textarea"
                      name="message"
                      className={
                        touched.message && errors.message
                          ? Style.error
                          : Style.field
                      }
                      placeholder="Message"
                      rows={"5"}
                    />
                    <ErrorMessage
                      name="message"
                      component="div"
                      style={{ color: "red", fontSize: "13px" }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    color: apiStatus.error ? "red" : "green",
                    // visibility: message ? "visible" : "hidden",
                  }}
                  className={Style.formMessage}
                >
                  {apiStatus.message}
                </div>
                <div className={Style.btnWrapper}>
                  <Button
                    disabled={
                      !!errors.email ||
                      !!errors.name ||
                      !!errors.phone ||
                      !!errors.message ||
                      values.name === "" ||
                      values.email === "" ||
                      values.message === "" ||
                      values.phone === ""
                    }
                    title={"Submit"}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Wrapper>
  );
};

export default ContactForm;
