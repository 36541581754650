import React, { useRef, useState, useEffect } from "react";

// Libraries
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules"; // Ensure Autoplay is imported

import featured2 from "../../assets/images/featured2.png";

// Styles
import "swiper/css";
import "swiper/css/pagination";
import Style from "./FeaturedProperties.module.css";
import Button from "../Button/Button";
import WhiteArrow from "../../assets/svgIcons/WhiteArrow";
import Wrapper from "../Wrapper/Wrapper";
import axios from "axios";
import feat1 from "../../assets/images/feat1.png";
import feat2 from "../../assets/images/feat2.jpg";
import feat3 from "../../assets/images/feat3.jpg";
import feat4 from "../../assets/images/feat4.jpg";
import feat5 from "../../assets/images/feat5.jpg";
import feat6 from "../../assets/images/feat6.jpg";
import feat7 from "../../assets/images/feat7.webp";
import feat8 from "../../assets/images/feat8.jpeg";
import feat9 from "../../assets/images/feat9.jpg";
import feat99 from "../../assets/images/feat99.jpg";
import feat10 from "../../assets/images/feat10.jpg";

const apartments = [
  {
    logo: feat1,
    price: "1.2M",
    name: "Bluewaters Residences",
    developer: "Meraas",
  },
  {
    logo: feat2,
    price: "1.6M",
    name: "Emaar Beachfront",
    developer: "Emaar",
  },
  {
    logo: feat3,
    price: "1.1M",
    name: "Port De La Mer",
    developer: "Meraas",
  },
  {
    logo: feat5,
    price: "1.8M",
    name: "Marina Views",
    developer: "Emaar",
  },
  {
    logo: feat6,
    price: "1.2M",
    name: "Ocean Cove",
    developer: "Meraas",
  },
  {
    logo: feat4,
    price: "1.8M",
    name: "Madinat Jumeirah Living (MJL)",
    developer: "Dubai Holding",
  },
];

const villas = [
  {
    logo: feat7,
    price: "7M",
    name: "Meydan District One",
    developer: "Meydan Group",
  },
  {
    logo: feat8,
    price: "12M",
    name: "Nad Al Sheba Gardens",
    developer: "Meraas",
  },
  {
    logo: feat9,
    price: "1.5M",
    name: "Amaranta",
    developer: "Dubai Properties",
  },
  {
    logo: feat7,
    price: "7M",
    name: "Meydan District One",
    developer: "Meydan Group",
  },
  {
    logo: feat99,
    price: "1.5M",
    name: "Amaranta",
    developer: "Dubai Properties",
  },
  {
    logo: feat7,
    price: "7M",
    name: "Meydan District One",
    developer: "Meydan Group",
  },
  {
    logo: feat10,
    price: "5M",
    name: "Nad Al Sheba Gardens",
    developer: "Meraas",
  },
];

const FeaturedProperties = () => {
  const swiperRef = useRef();
  const [active, setActive] = useState("flats");

  useEffect(() => {
    const fetchPropertyDetail = async () => {
      try {
        const response = await axios.get(
          "https://bayut.p.rapidapi.com/properties/detail?externalID=4937770",
          {
            headers: {
              "x-rapidapi-key":
                "f4d32d6363msh1e3ff2d1208e7e4p117100jsn53bd1223278c",
              "x-rapidapi-host": "bayut.p.rapidapi.com",
            },
            withCredentials: true,
          }
        );
        console.log(response.data); // Handle the response data here
      } catch (error) {
        console.error("Error fetching property details:", error);
      }
    };

    fetchPropertyDetail();
  }, []);

  return (
    <div className={Style.wrapper}>
      <Wrapper>
        <h1>Featured Properties</h1>

        <div className={Style.top}>
          <div className={Style.filterWrapper}>
            <div
              onClick={() => setActive("flats")}
              className={
                active === "flats" ? Style.filter : Style.filterInActive
              }
            >
              Flats
            </div>
            <div
              onClick={() => setActive("villas")}
              className={
                active === "villas" ? Style.filter : Style.filterInActive
              }
            >
              Villas
            </div>
          </div>
          <div className={Style.btns}>
            <div
              onClick={() => swiperRef.current?.slidePrev()}
              className={Style.btnLeft}
            >
              <WhiteArrow />
            </div>
            <div
              onClick={() => swiperRef.current?.slideNext()}
              className={Style.btnRight}
            >
              <WhiteArrow />
            </div>
          </div>
        </div>
      </Wrapper>

      <div className={Style.slider}>
        <Swiper
          modules={[Pagination, Autoplay]}
          slidesPerView={4}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          spaceBetween={25}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1050: {
              slidesPerView: 3,
            },
            1440: {
              slidesPerView: 4,
            },
          }}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
          className={Style.swiper}
        >
          {active === "flats"
            ? apartments.map((item, index) => (
                <SwiperSlide key={index}>
                  <SingleCard
                    logo={item.logo}
                    price={item.price}
                    name={item.name}
                    developer={item.developer}
                  />
                </SwiperSlide>
              ))
            : villas.map((item, index) => (
                <SwiperSlide key={index}>
                  <SingleCard
                    logo={item.logo}
                    price={item.price}
                    name={item.name}
                    developer={item.developer}
                  />
                </SwiperSlide>
              ))}
        </Swiper>
        {/* <div className={Style.viewBtn}>
          <Button title="Discover All" />
        </div> */}
      </div>
    </div>
  );
};

const SingleCard = ({ logo, href, price, name, developer }) => {
  return (
    <div className={Style.card}>
      <div
        style={{
          backgroundImage: `url(${logo})`,
        }}
        className={Style.propertyImage}
      ></div>
      <div className={Style.featuredTag}>Featured</div>
      <h4>AED {price}</h4>

      <h5>{name}</h5>

      <div className={Style.amenities}>
        {/* <div className={Style.amenity}>
          <LuBuilding2 />
          <p>3</p>
        </div> */}
        <div className={Style.amenity}>
          <p>{developer}</p>
        </div>
      </div>
    </div>
  );
};

export default FeaturedProperties;
