// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Button_btn__RBtNK,
.Button_disabledBtn__lVBKA {
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: #94792f;
  border-radius: 50%;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 15px;
  border: none;
}

.Button_btn__RBtNK svg,
.Button_disabledBtn__lVBKA svg {
  transform: rotate(45deg);
}

.Button_btn__RBtNK:hover,
.Button_disabledBtn__lVBKA:hover {
  color: #94792f;
  background: white;
}

.Button_disabledBtn__lVBKA {
  opacity: 0.5;
}
`, "",{"version":3,"sources":["webpack://./src/components/Button/Button.module.css"],"names":[],"mappings":"AAAA;;EAEE,YAAY;EACZ,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,YAAY;AACd;;AAEA;;EAEE,wBAAwB;AAC1B;;AAEA;;EAEE,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".btn,\n.disabledBtn {\n  width: 150px;\n  height: 150px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: white;\n  background: #94792f;\n  border-radius: 50%;\n  font-size: 16px;\n  font-weight: 500;\n  cursor: pointer;\n  margin-top: 15px;\n  border: none;\n}\n\n.btn svg,\n.disabledBtn svg {\n  transform: rotate(45deg);\n}\n\n.btn:hover,\n.disabledBtn:hover {\n  color: #94792f;\n  background: white;\n}\n\n.disabledBtn {\n  opacity: 0.5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": `Button_btn__RBtNK`,
	"disabledBtn": `Button_disabledBtn__lVBKA`
};
export default ___CSS_LOADER_EXPORT___;
