import React from "react";

const WhiteArrow = () => {
  return (
    <svg
      width="28"
      height="17"
      viewBox="0 0 28 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.1639 0.778368C8.894 3.30601 3.7081 8.35084 1.12402 8.30908"
        stroke="#FBF9F6"
        stroke-width="1.3"
        stroke-linecap="round"
      />
      <path
        d="M10.9154 16.1603C8.72828 13.5606 3.7081 8.35084 1.12402 8.30908"
        stroke="#FBF9F6"
        stroke-width="1.3"
        stroke-linecap="round"
      />
      <path
        d="M2.97168 8.55713L27.1719 8.94822"
        stroke="#FBF9F6"
        stroke-width="1.3"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default WhiteArrow;
