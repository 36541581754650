import React from "react";

import Wrapper from "../Wrapper/Wrapper";
import PartnersSlide from "../../components/PartnersSlide/PartnersSlide";

// Styles
import Style from "./WhoWeAre.module.css";

const data = [
  {
    title: "Projects",
    digit: 250,
  },
  {
    title: "Multifamily Projects",
    digit: 30,
  },
  {
    title: "Homes and Lots Delivered",
    digit: 5000,
  },
  {
    title: "Retail Developments",
    digit: 30,
  },
];

const WhoWeAre = () => {
  return (
    <div className={Style.who}>
      <Wrapper>
        <div className={Style.titles}>
          <h1>Who We Are</h1>
          <p
            style={{
              marginBottom: "15px",
            }}
          >
            Our holistic real estate ecosystem is designed to provide clients,
            brokers, and employees with a seamless experience, offering a
            comprehensive range of services. These include Property Sales,
            Resales, Property Investment Management and more. Each service is
            meticulously crafted to ensure the highest level of quality and
            satisfaction.
          </p>
          <p>
            Our collaboration with the Dubai Land Department is a testament to
            our commitment to market growth and sustainability. We contribute to
            shaping Dubai’s real estate future by sharing expert insights,
            market research, and providing valuable feedback to enhance
            regulatory practices, aligning with the vision of His Highness
            Sheikh Mohammed bin Rashid Al Maktoum.
          </p>
        </div>
        <PartnersSlide />
      </Wrapper>
    </div>
  );
};

export default WhoWeAre;
