import React from "react";

// Styles
import Style from "./Wrapper.module.css";

const Wrapper = ({ children }) => {
  return <div className={Style.wrap}>{children}</div>;
};

export default Wrapper;
