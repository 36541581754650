// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Wrapper_wrap__amqWz {
  padding-inline: 40px;
  max-width: 1440px;
  margin-inline: auto;
}

@media screen and (max-width: 767px) {
  .Wrapper_wrap__amqWz {
    padding-inline: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Wrapper/Wrapper.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE;IACE,oBAAoB;EACtB;AACF","sourcesContent":[".wrap {\n  padding-inline: 40px;\n  max-width: 1440px;\n  margin-inline: auto;\n}\n\n@media screen and (max-width: 767px) {\n  .wrap {\n    padding-inline: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `Wrapper_wrap__amqWz`
};
export default ___CSS_LOADER_EXPORT___;
