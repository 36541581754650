import React from "react";

// Style
import Style from "./PrivacyPolicyView.module.css";
import NavBar from "../../../components/NavBar/NavBar";
import Footer from "../../../components/Footer/Footer";
import Wrapper from "../../../components/Wrapper/Wrapper";

const PrivacyPolicyView = () => {
  return (
    <>
      <NavBar />
      <Wrapper>
        <div className={Style.privacyWrapper}>
          {/* <h1>Privacy Policy</h1> */}
          <div className={Style.container}>
            <h1 className={Style.title}>Privacy Policy</h1>
            <p className={Style.intro}>
              This Privacy Policy governs the manner in which we collect, use,
              maintain, and disclose information collected from users (each, a
              "User") of the www.palm-jabel-ali-uae.com website ("Site"). This
              Privacy Policy applies to the Site and all products and services
              offered through this platform.
            </p>

            <section className={Style.section}>
              <h2>Personally Identifiable Information</h2>
              <p>
                We may collect personally identifiable information from Users in
                various ways, including, but not limited to, when Users visit
                our Site, register, subscribe to a newsletter, fill out a form,
                or engage in other activities, services, features, or resources
                provided on our Site. Users may be asked for their name, email
                address, and phone number. Users may, however, choose to visit
                the Site anonymously. We will collect personally identifiable
                information from Users only if they voluntarily provide it.
                Users can always choose not to supply personally identifiable
                information, but this may limit their ability to engage in
                certain Site-related activities.
              </p>
            </section>

            <section className={Style.section}>
              <h2>Non-Personally Identifiable Information</h2>
              <p>
                We may collect non-personally identifiable information about
                Users whenever they interact with our Site. This information may
                include the browser type, computer details, and technical
                information related to the means of connection, such as the
                operating system, Internet service provider, and other similar
                information.
              </p>
            </section>

            <section className={Style.section}>
              <h2>Web Browser Cookies</h2>
              <p>
                Our Site may use "cookies" to enhance User experience. Cookies
                are small data files stored on Users' devices for record-keeping
                purposes and sometimes to track information. Users may set their
                web browser to refuse cookies or to alert them when cookies are
                being sent. However, some parts of the Site may not function
                properly if cookies are disabled.
              </p>
            </section>

            <section className={Style.section}>
              <h2>How We Use Collected Information</h2>
              <p>
                We may collect and use Users’ personal information for the
                following purposes:
              </p>
              <ul className={Style.list}>
                <li>
                  <strong>To improve customer service:</strong> Information
                  helps us respond more efficiently to customer service requests
                  and support needs.
                </li>
                <li>
                  <strong>To personalize user experience:</strong> We may use
                  information in the aggregate to understand how Users as a
                  group utilize the Site’s services and resources.
                </li>
                <li>
                  <strong>To improve our Site:</strong> Feedback provided by
                  Users may help us improve our products and services.
                </li>
                <li>
                  <strong>To send periodic emails:</strong> We may use the email
                  address to respond to inquiries or other requests. If a User
                  opts into our mailing list, they may receive emails with
                  updates, company news, or related product information.
                  Unsubscribe instructions are included in each email for Users
                  who wish to opt out.
                </li>
              </ul>
            </section>

            <section className={Style.section}>
              <h2>Sharing Personal Information</h2>
              <p>
                We do not sell, rent, or trade personal information. We may
                share information within affiliated entities or external
                associates involved in conducting our business, which may
                involve data transfer outside certain regions. Additionally, we
                may share aggregated demographic information not linked to any
                personally identifiable information with business partners,
                affiliates, and advertisers.
              </p>
            </section>

            <section className={Style.section}>
              <h2>Third-Party Websites</h2>
              <p>
                Our Site may contain links to sites and services of partners,
                suppliers, advertisers, sponsors, licensors, and other third
                parties. We do not control the content or links that appear on
                these sites and are not responsible for their privacy practices.
                Users are encouraged to review the privacy policies of any
                linked sites before interacting with them.{" "}
              </p>
            </section>

            <section className={Style.section}>
              <h2>Changes to This Privacy Policy</h2>
              <p>
                We reserve the right to update this Privacy Policy at any time.
                Any changes will be reflected with an updated date at the bottom
                of this page. We encourage Users to periodically review this
                page to stay informed about how we protect the information we
                collect. Continued use of the Site after any changes constitutes
                acceptance of those changes.
              </p>
            </section>

            <section className={Style.section}>
              <h2>Your Acceptance of These Terms</h2>
              <p>
                By using this Site, you signify your acceptance of this Privacy
                Policy. If you do not agree, please do not use the Site.
                Continued use of the Site following any changes to this policy
                will be deemed your acceptance of those changes.
              </p>
            </section>

            <section className={Style.section}>
              <h2>Contacting Us</h2>
              <p>
                If you have any questions about this Privacy Policy, the
                practices of this Site, or your interactions with us via this
                Site, please contact us at{" "}
                <a
                  href="mailto:info@palm-jabel-ali-uae.com"
                  className={Style.link}
                >
                  info@palm-jabel-ali-uae.com
                </a>
                .
              </p>
              <br />
              <br />
              <p>@2024 All Righs Reserved</p>
            </section>
          </div>
        </div>
      </Wrapper>
      <Footer />
    </>
  );
};

export default PrivacyPolicyView;
