// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HomePageView_scrollTopButton__Twouy {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  background-color: #94792f;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
  opacity: 0.8;
}

.HomePageView_scrollTopButton__Twouy:hover {
  opacity: 1;
}

.HomePageView_whatsappButton__EUxv3 {
  position: fixed;
  bottom: 20px;
  right: 80px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 32323;
}

.HomePageView_whatsappButton__EUxv3 img {
  width: 100%;
  height: auto;
}

.HomePageView_whatsappButton__EUxv3 {
  padding: 10px;
  border-radius: 50%;
  background: #25d366;
}

.HomePageView_whatsappButton__EUxv3 svg {
  color: white;
  background: #25d366;
}
`, "",{"version":3,"sources":["webpack://./src/pages/main/HomePageView/HomePageView.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,WAAW;EACX,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,WAAW;EACX,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,mBAAmB;AACrB","sourcesContent":[".scrollTopButton {\n  position: fixed;\n  bottom: 20px;\n  right: 20px;\n  width: 40px;\n  height: 40px;\n  background-color: #94792f;\n  color: white;\n  border: none;\n  border-radius: 50%;\n  cursor: pointer;\n  font-size: 20px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  transition: opacity 0.3s ease;\n  opacity: 0.8;\n}\n\n.scrollTopButton:hover {\n  opacity: 1;\n}\n\n.whatsappButton {\n  position: fixed;\n  bottom: 20px;\n  right: 80px;\n  width: 40px;\n  height: 40px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  z-index: 32323;\n}\n\n.whatsappButton img {\n  width: 100%;\n  height: auto;\n}\n\n.whatsappButton {\n  padding: 10px;\n  border-radius: 50%;\n  background: #25d366;\n}\n\n.whatsappButton svg {\n  color: white;\n  background: #25d366;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scrollTopButton": `HomePageView_scrollTopButton__Twouy`,
	"whatsappButton": `HomePageView_whatsappButton__EUxv3`
};
export default ___CSS_LOADER_EXPORT___;
