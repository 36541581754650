import React, { useState, useEffect } from "react";

// Components
import Wrapper from "../Wrapper/Wrapper";

// Images
import logo from "../../assets/images/logo.svg";

import classNames from "../../utils/classNames";

// Styles
import Style from "./NavBar.module.css";
import { Link } from "react-router-dom";

const NavBar = () => {
  const [open, setOpen] = useState(false);

  // Close the menu on scroll
  useEffect(() => {
    const handleScroll = () => setOpen(false);

    // Add event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up event listener on component unmount
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className={Style.navBar}>
      <Wrapper>
        <div className={Style.navWrap}>
          <Link to="/">
            <img src={logo} alt="Logo" />
          </Link>
          <div></div>
          <div
            onClick={() => {
              setOpen(!open);
            }}
            className={Style.menu}
          >
            <div className={Style.navBarIcon}>
              <div
                className={classNames(
                  Style.navBarIconMobile,
                  open ? Style.active : Style.notActive
                )}
              >
                <span className={Style.iconPartOne}></span>
                <span className={Style.iconPartTwo}></span>
                <span className={Style.iconPartThree}></span>
              </div>
            </div>
            <p className={open ? Style.menuIsOpen : Style.menuIsNotOpen}>
              <a href="#whoWeAre">Who We Are</a>
              <a href="#featured">Featured Properties</a>
              <a href="#getToKnow">Get To Know Us</a>
              <a href="#services">Our Services</a>
              <a href="#whyUs">Why Us</a>
              <a href="#success">Success Stories</a>
              <a href="#contact">Contact Us</a>
            </p>
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export default NavBar;
