import React, { useRef } from "react";

// Libraries
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";

import Style from "./SuccessStories.module.css";
import Wrapper from "../Wrapper/Wrapper";
import client1 from "../../assets/images/client1.jpg";
import client2 from "../../assets/images/apartment.jpg";
import client3 from "../../assets/images/client3.webp";
import client4 from "../../assets/images/client4.jpg";
import "swiper/css";
import "swiper/css/pagination";
import WhiteArrow from "../../assets/svgIcons/WhiteArrow";
import spiral from "../../assets/images/spiral.png";

const data = [
  {
    fullName: "Ahmed Al Mansouri",
    commentTItle: "A Smooth Journey to Homeownership",
    commentSubTitle:
      "As a first-time buyer, I was unfamiliar with the process of purchasing a home. The team at Solitaire Crest Real Estate expertly guided me through each step, ensuring the experience was clear and hassle-free. With their dedicated support, I am now a proud owner of my dream apartment in Dubai Marina.",
    img: client4,
  },
  {
    fullName: "Ali Khalifa",
    commentTItle: "A Seamless First Home Purchase",
    commentSubTitle:
      "As a first-time buyer, I was unsure about the process of purchasing a home. The team at Solitaire guided me through each step, ensuring everything was transparent and straightforward. Thanks to their support, I’m now the proud owner of my dream apartment in Dubai Marina.",
    img: client3,
  },
  {
    fullName: "Sarah Malik",
    commentTItle: "A Dream Investment Realized",
    img: client2,
    commentSubTitle:
      "As an investor, I was looking for a property that offered both value and strong returns. The team at Solitaire Crest Real Estate took the time to understand my needs and presented me with a range of excellent options. Their market knowledge and professionalism made the entire process seamless. I am now the owner of a high-yield property in Downtown Dubai and couldn’t be happier with my investment.",
  },
  {
    fullName: "David Wilson",
    img: client1,
    commentTItle: "Expanding My Business with Confidence",
    commentSubTitle:
      "I needed a commercial space to expand my growing business and wasn’t sure where to start. The experts at Solitaire Crest Real Estate helped me navigate the market, offering valuable insights and advice. Thanks to their efforts, I secured the perfect office space in Business Bay that meets all my needs. Their dedication and expertise truly made a difference in making my expansion plans a reality.",
  },
];

const SuccessStories = () => {
  const swiperRef = useRef();

  return (
    <div className={Style.success}>
      <Wrapper>
        <h1>Clients Success Stories</h1>
        <p>
          We believe in long-lasting relationships with our clients. Over the
          years, we have helped thousands of homeowners, investors, and
          businesses achieve their real estate dreams. Read our Success Stories
          to see how we've transformed our clients’ experiences into success.
        </p>
        <Swiper
          modules={[Pagination, Autoplay]}
          slidesPerView={4}
          autoplay={{
            delay: 4500,
            disableOnInteraction: false,
          }}
          spaceBetween={25}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
          }}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
          className={Style.swiper}
        >
          {data.map((item, index) => (
            <SwiperSlide key={index}>
              <SingleCard {...item} />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className={Style.btns}>
          <div
            onClick={() => swiperRef.current?.slidePrev()}
            className={Style.btnLeft}
          >
            <WhiteArrow />
          </div>
          <div
            onClick={() => swiperRef.current?.slideNext()}
            className={Style.btnRight}
          >
            <WhiteArrow />
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

const SingleCard = ({ fullName, commentSubTitle, commentTItle, img }) => {
  return (
    <div className={Style.card}>
      <img src={img} alt="Logo" />
      {/* <div
        className={Style.image}
        style={{
          background: `url(${img})`,
        }}
      /> */}
      <img src={spiral} alt="" />
      <div className={Style.storyText}>
        <h5>
          {fullName} -
          <br />
          <br />
          {commentTItle}
        </h5>
        <h6>{commentSubTitle}</h6>
      </div>
    </div>
  );
};

export default SuccessStories;
