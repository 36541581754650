import React from "react";
import Style from "./Footer.module.css";
import logo from "../../assets/images/logo.svg";
import Wrapper from "../Wrapper/Wrapper";
import { FaFacebookF, FaTiktok, FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className={Style.footer}>
      <div />
      <div />
      <div />
      <div />
      <div className={Style.links}>
        <div className={Style.logo}>
          <img src={logo} alt="" />
        </div>
        <Wrapper>
          <div className={Style.linksGrid}>
            <div>
              <div className={Style.title}>
                <span></span>Contact Us
              </div>
              <div className={Style.subTitle}>+971 585 351 113</div>
              <div className={Style.subTitle}>info@solitairecrest.com</div>
              <div className={Style.subTitle}>RERA#: 1403581</div>
            </div>
            <div>
              <div className={Style.title}>
                <span></span>Find Us
              </div>
              <div className={Style.subTitle}>
                One by Omniyat, Business Bay, Dubai, UAE
              </div>
            </div>
            <div>
              <div className={Style.title}>
                <span></span>Quick Links
              </div>
              <div className={Style.subTitle}>About Us</div>
              <div className={Style.subTitle}>Services</div>
              {/* <Link to="/privacy-policy">Privacy Policy</Link> */}
            </div>
            <div>
              <div className={Style.title}>
                <span></span>Follow Us
              </div>
              <div className={Style.followUs}>
                <a
                  href="https://www.facebook.com/profile.php?id=61564036276505"
                  target="_blank"
                  rel="noreferrer"
                  className={Style.iconWrapper}
                >
                  <FaFacebookF />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://x.com/SolitaireCrest"
                  className={Style.iconWrapper}
                >
                  <FaXTwitter />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.instagram.com/solitairecrestproperties/"
                  className={Style.iconWrapper}
                >
                  <FaInstagram />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.tiktok.com/@solitairecrestproperties"
                  className={Style.iconWrapper}
                >
                  <FaTiktok />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.linkedin.com/company/solitaire-crest-properties/"
                  className={Style.iconWrapper}
                >
                  <FaLinkedin />
                </a>
              </div>
            </div>
          </div>
        </Wrapper>
      </div>
    </div>
  );
};

export default Footer;
