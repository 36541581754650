import React, { useEffect, useState } from "react";

// Components
import NavBar from "../../../components/NavBar/NavBar";
import Hero from "../../../components/Hero/Hero";
import WhoWeAre from "../../../components/WhoWeAre/WhoWeAre";
import GetToKnow from "../../../components/GetToKnow/GetToKnow";
import WhyUs from "../../../components/WhyUs/WhyUs";
import FeaturedProperties from "../../../components/FeaturedProperties/FeaturedProperties";
import SuccessStories from "../../../components/SuccessStories/SuccessStories";
import ContactForm from "../../../components/ContactForm/ContactForm";
import Footer from "../../../components/Footer/Footer";

import Style from "./HomePageView.module.css";
import { FaWhatsapp } from "react-icons/fa";

const HomePageView = () => {
  const [showScroll, setShowScroll] = useState(false);

  // Track scroll position
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowScroll(true);
      } else {
        setShowScroll(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <NavBar />
      <Hero />
      <section id="whoWeAre">
        <WhoWeAre />
      </section>
      <section id="featured">
        <FeaturedProperties />
      </section>
      <section id="getToKnow">
        <GetToKnow />
      </section>
      <section id="whyUs">
        <WhyUs />
      </section>
      <section id="success">
        <SuccessStories />
      </section>
      <section id="contact">
        <ContactForm />
      </section>
      <Footer />
      <a
        href="https://wa.me/971585351113"
        target="_blank"
        rel="noopener noreferrer"
        className={Style.whatsappButton}
      >
        <FaWhatsapp size={30} />
      </a>
      {showScroll && (
        <button onClick={scrollToTop} className={Style.scrollTopButton}>
          ↑
        </button>
      )}
    </>
  );
};

export default HomePageView;
