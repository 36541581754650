import React, { useEffect, useRef, useState } from "react";

// Style
import Style from "./GetToKnow.module.css";
import Wrapper from "../Wrapper/Wrapper";
import Button from "../Button/Button";
import service1 from "../../assets/images/service1.jpg";
import service2 from "../../assets/images/service2.jpg";
import service3 from "../../assets/images/service3.jpg";
import service4 from "../../assets/images/service4.png";
import bl from "../../assets/images/bluredMan.png";

const data = [
  {
    img: service1,
    title: "Residential Sales & Leasing",
    subTitle: `We specialize in helping clients find the perfect home or investment in Dubai’s vibrant residential market. Our team offers personalized solutions for buying or leasing, with a portfolio that includes luxury villas, modern apartments, and family-friendly communities. <br/><br/> Whether you’re purchasing your dream home or seeking a rental, we ensure a smooth and stress-free process from start to finish. For investors, we provide expert guidance on opportunities that maximize returns, backed by deep market insights. Our commitment to transparency, honesty, and exceptional service creates long-lasting client relationships. With our local expertise and vast network, we make real estate decisions easier and more rewarding. Let us help you discover your ideal home or investment with confidence and care.`,
  },
  {
    img: service2,
    title: "Commercial Properties",
    subTitle: `We specialize in the sale and leasing of a diverse range of commercial properties across Dubai's most dynamic business districts. Our portfolio includes retail shops, office spaces, warehouses, commercial land, and mixed-use developments, ensuring that we meet the unique needs of every client.<br/><br/> For retail businesses, our team understands the importance of finding the right space to showcase your brand and drive sales, and we are here to guide you through the selection process.<br/><br/> If you're looking for office spaces, we provide a variety of options, from modern co-working environments to private offices in prestigious buildings, tailored to accommodate your operational needs. Our leasing solutions are flexible, allowing businesses to scale as needed while enjoying high-quality amenities.<br/><br/> For those interested in investment opportunities, we offer commercial land options ideal for development, as well as established warehouses that cater to logistics and distribution businesses. Our extensive knowledge of market trends and property values enables us to provide valuable insights that help you make informed decisions.<br/><br/> Let us assist you in navigating the commercial property landscape, securing the ideal space that aligns with your business goals and vision for growth.`,
  },
  {
    img: service3,
    title: "Investment Consultancy",
    subTitle: `We understand that investing in property can be a significant commitment, and our expert team is here to guide you every step of the way. <br/><br/>We provide comprehensive market analysis, helping you identify lucrative opportunities that align with your investment goals. Whether you’re interested in residential properties, commercial real estate, or mixed-use developments, our insights into current market trends and future projections ensure you’re well-informed.<br/><br/> Our team offers personalized investment strategies tailored to your financial objectives and risk tolerance. We help you understand the potential returns, capital appreciation, and rental yields, enabling you to make sound decisions that maximize your investment’s value. <br/><br/>In addition to property selection, we assist with due diligence, ensuring that you’re aware of all legal, financial, and regulatory considerations. Our extensive network of contacts also provides access to exclusive off-market opportunities that may not be available to the general public.`,
  },
];

const GetToKnow = () => {
  return (
    <div className={Style.getToKnow}>
      <div className={Style.knowUs}>
        <img src={bl} alt="" />
        <Wrapper>
          <h1>Get To Know Us</h1>
          <div className={Style.knowGrid}>
            <div className={Style.human} />
            <div>
              <h4
                style={{
                  marginBottom: "15px",
                }}
              >
                Led by a leadership team with over 30 years of global and local
                experience, Solitaire Crest has established itself in Dubai’s
                property market. By leveraging data analytics, research, and
                exceptional customer service, we ensure that every client
                receives value beyond their expectations.
              </h4>
              <h4>
                At Solitaire Crest, our core values—integrity, transparency,
                innovation, and collaboration—are at the heart of everything we
                do. We believe in the strength of partnerships and teamwork, and
                we invite clients, partners, and fellow real estate
                professionals to join us in building a brighter future for
                Dubai’s thriving real estate market.
              </h4>
              <Button title="Read More" />
            </div>
          </div>
        </Wrapper>
      </div>
      <div id="services" className={Style.services}>
        <Wrapper>
          <h1>Our Services</h1>
          <h4>
            Explore the wide range of services we provide to meet all your real
            estate needs:
          </h4>

          <div className={Style.serviceGrid}>
            {data.map((item, index) => {
              return <Service key={index} {...item} />;
            })}
          </div>
        </Wrapper>
      </div>
    </div>
  );
};

const Service = ({ img, title, subTitle }) => {
  const [isVisible, setIsVisible] = useState(false);
  const serviceRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // If the element is visible
          if (entry.isIntersecting) {
            setIsVisible(true);
          }
          //  else {
          //   setIsVisible(false);
          // }
        });
      },
      { threshold: 0.6 }
    );

    if (serviceRef.current) {
      observer.observe(serviceRef.current);
    }

    return () => {
      if (serviceRef.current) {
        observer.unobserve(serviceRef.current);
      }
    };
  }, []);

  return (
    <div
      className={`${Style.service} ${
        isVisible ? Style["in-view"] : Style["out-view"]
      }`}
      ref={serviceRef}
    >
      <div>
        <h2>*&nbsp;{title}</h2>
        <p dangerouslySetInnerHTML={{ __html: subTitle }}></p>
      </div>
      {/* <img src={img} alt={title} /> */}
      <div
        style={{
          backgroundImage: `url(${img})`,
        }}
        className={Style.servImg}
      ></div>
    </div>
  );
};

export default GetToKnow;
