import React from "react";

import { IoIosArrowRoundUp } from "react-icons/io";

import Style from "./Button.module.css";

const Button = ({ title, disabled }) => {
  return (
    <button className={disabled ? Style.disabledBtn : Style.btn}>
      {title}
      <IoIosArrowRoundUp size={30} />
    </button>
  );
};

export default Button;
