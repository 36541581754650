import React from "react";

// Styles
import Style from "./Hero.module.css";
import Wrapper from "../Wrapper/Wrapper";
import Button from "../Button/Button";

const Hero = () => {
  return (
    <div>
      <div className={Style.heroImg} />
      <div className={Style.grad}>
        <Wrapper>
          <h1 className={Style.heroTitle}>
            Expert Guidance,
            <br />
            Exceptional Properties
          </h1>
          <div className={Style.leftWrapper}>
            <p
              style={{
                marginBottom: "15px",
              }}
            >
              At Solitaire Crest Real Estate, we help you navigate through the
              dynamic real estate market in Dubai with confidence. Whether
              you're searching for your ideal home, selling a beloved property,
              or seeking profitable investment opportunities, or looking for an
              office, our expert team is here to provide personalized solutions.
              With our in-depth knowledge of the local market and a commitment
              to delivering exceptional service, we tailor our approach to match
              your specific goals.
            </p>
            <p>
              We specialize in a wide range of property types, including luxury
              villas, modern apartments, commercial spaces, and off-plan
              developments. We are passionate about delivering results that
              exceed expectations. At Solitaire Crest, your satisfaction is our
              priority, and we aim to build lasting relationships based on trust
              and transparency. Whether you’re a first-time buyer or a seasoned
              investor, we’re here to help you achieve success in Dubai’s
              thriving real estate market. Let us turn your property aspirations
              into reality with our unwavering dedication to excellence.
            </p>
            <Button title="Read More" />
          </div>
        </Wrapper>
      </div>
    </div>
  );
};

export default Hero;
